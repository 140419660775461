import React from 'react';
import styled from 'styled-components';
import Seo from '../components/SEO'
import featuredImage from '../images/about.jpg'

const AboutPage = () => {
  return (
    <>
      <Seo 
        title='About Us' 
        description='The SARPL is dedicated to players and teams who want to improve their skills on the field and have fun doing it!'
        image='https://www.sarpl.co.za/static/about-d266879505a949dfb844e4a232f9db0b.jpg' 
      />
      <StyledAboutPage>
        <div className="site-container">
          <img src={featuredImage} alt="" />

          <div className="content">
            <h1>Our <br/>Story</h1>
            <p>The SARPL is currently registered as a Pty Ltd and Paul Grobler has been the only director/owner of the league since 2013. The league originally started by Danny and Lindie de Bruyne (Founders and Previous owners of Nova Paintball Field in Pretoria) in 2009 and is currently the biggest national league in South Africa.</p>
            <p>The SARPL is dedicated to players and teams who want to improve their skills on the field and have fun doing it! In the SARPL we don’t primarily play for prizes, we don’t play for blood or money, we play to learn, improve and enjoy a fun environment where all players are encouraged to share their knowledge and help other players and teams improve, growing paintball, and the level of paintball in South Africa. Depending on sponsors we do have prizes at some of our events during the year and season finals as an extra bonus.</p>
          </div>
        </div>
      </StyledAboutPage>
    </>
  );
};

const StyledAboutPage = styled.main`
  padding: 2rem 0;
  display: flex;

  .site-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    /* min-height: calc(100vh - 5rem); */
    min-height: 100%;
  }

  img {
    height: 500px;
    width: 400px;
    object-fit: cover;
    object-position: 71%;
    border-radius: 10px;
  }

  h1 {
    font-size: 5rem;
    line-height: 1.05;
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
  }

  p {
    margin: 1rem 0;
    line-height: 1.3;
  }

  @media (max-width: 768px) {
    display: initial;

    .site-container {
      flex-direction: column;
    }

    img {
      width: 100%;
      /* height: 250px; */
      height: initial;
      aspect-ratio: 5 / 2;
      object-position: top;
    }
  }

  @media (max-width: 480px) {
    /* padding: 2rem 1rem; */

    img {
      aspect-ratio: 2 / 1;
    }
  }
`

export default AboutPage;
